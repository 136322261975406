/**
 *
 * @param {*} time 时间
 * @returns
 */
const getTime = (time) => {
  const data = new Date(time);
  const year = data.getFullYear();
  const month = ("0" + (data.getMonth() + 1)).slice(-2);
  const day = ("0" + data.getDate()).slice(-2);
  const dataTime = `${year}-${month}-${day}`;
  return dataTime;
};
export { getTime };
