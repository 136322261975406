<template>
  <div>
    <bread-crumb :crumbData="['登录账号', data ? '修改' : '新增']" :chooseCrumbData="2" @close="onClose"></bread-crumb>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
      style="padding: 50px; width: 40%">
      <el-form-item label="站长手机：" prop="contact_phone">
        <el-input v-model="ruleForm.contact_phone"></el-input>
      </el-form-item>
      <el-form-item label="站长姓名：" prop="contact_name">
        <el-input v-model="ruleForm.contact_name"></el-input>
      </el-form-item>
      <el-form-item label="商铺名称：" prop="site_name">
        <el-input v-model="ruleForm.site_name"></el-input>
      </el-form-item>
      <el-form-item label="管理员：" prop="admin" v-if="data">
        <el-select v-model="ruleForm.admin" placeholder="请选择">
          <el-option v-for="item in admins" :key="item.id" :label="item.realName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所在地区：" prop="region">
        <span style="font-size: 14px; margin-right: 10px">{{
          ruleForm.region
        }}</span>
        <el-cascader style="width:100px" placeholder="请选择地区" :options="options" v-model="ruleForm.region"
          @change="ruleForm.region = ruleForm.region.join('')" />
        <!-- <el-button type="primary" @click="dialogVisible = true">选择</el-button> -->
      </el-form-item>
      <el-form-item label="详细地址：" prop="location">
        <el-input v-model="ruleForm.location"></el-input>
      </el-form-item>

      <el-form-item label="状态：" required>
        <el-radio-group v-model="ruleForm.status">
          <el-radio label="待审核"></el-radio>
          <el-radio label="已审核"></el-radio>
          <el-radio label="已关闭"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="排序：">
        <el-input v-model="ruleForm.sort"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
      </el-form-item>
    </el-form>
    <!-- 
    <locationAdd v-if="dialogVisible" :dialogVisible="dialogVisible" @dialogVisible="onDialogVisible"
      @rowClick="onRowClick"></locationAdd> -->
  </div>
</template>
<script>
import { pcaTextArr } from "element-china-area-data";
import locationAdd from "../../../../addAddress/location-add.vue";
export default {
  components: {
    locationAdd,
  },
  props: ["data"],
  data() {
    return {
      options: pcaTextArr,
      ruleForm: {
        contact_phone: "",
        contact_name: "",
        site_name: "",
        region: "",
        location: "",
        status: "",
        sort: "",
        admin: "",
        station_agent: "",
        commercial_id: "",
        id: 0,
      },
      rules: {
        contact_phone: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        contact_name: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        site_name: [{ required: true, message: "必填字段", trigger: "blur" }],
        region: [{ required: true, message: "必填字段", trigger: "blur" }],
        location: [{ required: true, message: "必填字段", trigger: "blur" }],
        status: [{ required: true, message: "必填字段", trigger: "change" }],
      },
      admins: [],
      dialogVisible: false,
    };
  },
  mounted() {
    this.ruleForm.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.$http
      .post("/system/Employee/lst", {
        commercial_id: this.ruleForm.commercial_id,
      })
      .then((res) => {
        this.admins = res.data.data;
      });
    this.ruleForm.station_agent =
      localStorage.getItem("user_id") || sessionStorage.getItem("user_id");
    if (this.$props.data) {
      this.ruleForm = this.$props.data;
    }
  },
  computed: {},
  methods: {
    onClose(data) {
      this.$emit("close", data);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.$props.data) {
            delete this.ruleForm.admin;
            console.log(this.ruleForm);
          }
          this.$http
            .post("/system/pick_site/save", this.ruleForm)
            .then((res) => {
              if (res.data.status == "success") {
                this.onClose(0);
                this.$message({
                  type: "success",
                  message: res.data.msg,
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onDialogVisible(data) {
      this.dialogVisible = data;
    },
    onRowClick(location, address) {
      this.ruleForm.region = location;
      this.ruleForm.location = address;
    },
  },
};
</script>
<style scoped></style>
