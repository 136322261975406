<template>
  <div>
    <bread-crumb
      :crumbData="['自提站点', '商品管理']"
      :chooseCrumbData="2"
      @close="onClose"
    ></bread-crumb>
    <search-case
      :searchData="searchData"
      @inquireBtnClick="inquireBtnClick"
    ></search-case>

    <div style="padding: 20px">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        border
        style="width: 100%"
      >
        <el-table-column prop="shop_id" label="店铺名称"></el-table-column>
        <el-table-column prop="product_name" label="商品名称"></el-table-column>
        <el-table-column
          prop="sales_quantity"
          sortable
          label="销售数量"
        ></el-table-column>
        <el-table-column
          prop="sales_amount"
          sortable
          label="销售金额"
        ></el-table-column>
        <el-table-column
          prop="residual_inventory"
          sortable
          label="剩余库存"
        ></el-table-column>
      </el-table>
      <div class="tableBottom">
        <span style=""
          >显示第&nbsp;{{
            tableData.length == 0 ? 0 : (page - 1) * length + 1
          }}&nbsp;至&nbsp;{{
            (page - 1) * length + tableData.length
          }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
        >

        <div
          style="display: flex; justify-content: flex-end; align-items: center"
        >
          <el-button>首页</el-button>
          <el-button>上页</el-button>
          <el-pagination
            background
            layout=" pager,slot"
            :total="1"
          ></el-pagination>
          <el-button>下页</el-button>
          <el-button>末页</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getTime } from "../../../../../until/getTime";
export default {
  data() {
    return {
      searchData: {
        date: "",
        selectSpan1: "自提站点：",
        option1: "全部",
        optiones1: ["全部"],
        searchBtn1: true,
        searchBtn2: true,
        QueryContent: "",
        inquirePlaceholder: "输入要查询的内容",
        isInquireValue: true,
        isInquireBtn: true,
        secondSelectSpan: "自提站点",
        isSecondSelect: true,
        secondSelectPlaceholder: "请选择",
        secondOptiones: ["全部"],
      },

      tableData: [],

      tableFinshNum: 0,
      page: 1,
      length: 10,
      commercial_id: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.getTabList();
  },
  computed: {},
  methods: {
    onClose(data) {
      this.$emit("close", data);
    },
    getTabList() {
      this.$http
        .post("/system/shop_statistics/lst", {
          currentPage: this.page,
          currentLength: this.length,
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    inquireBtnClick(data) {
      this.$http
        .post("/system/shop_statistics/search", {
          firstTime: getTime(data.dateRange[0]),
          lastTime: getTime(data.dateRange[1]),
          search: data.inquireValue,
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .cell {
  padding: 0 !important;
}
</style>
