<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
      v-if="isPop"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="选择所在地：" required>
            <el-col :span="4">
              <el-form-item prop="province">
                <el-select
                  v-model="ruleForm.province"
                  placeholder="请选择"
                  @focus="city1Focus"
                  @change="city1Change"
                >
                  <el-option
                    v-for="item in provinces"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" v-if="ruleForm.province">
              <el-form-item prop="city">
                <el-select
                  v-model="ruleForm.city"
                  placeholder="请选择"
                  @focus="city2Focus"
                  @change="city2Change"
                >
                  <el-option
                    v-for="item in cities"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" v-if="ruleForm.city">
              <el-form-item prop="region">
                <el-select
                  v-model="ruleForm.region"
                  placeholder="请选择"
                  @focus="city3Focus"
                  @change="city3Change"
                >
                  <el-option
                    v-for="item in regiones"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="小区/大楼：" prop="building">
            <div style="display: flex">
              <el-input
                style="width: 50%; margin-right: 5px"
                v-model="ruleForm.building"
                placeholder="请输入大楼/小区名称搜索"
              ></el-input>
              <el-button type="primary" @click="getAddressList">搜索</el-button>
              <el-button type="primary">确定</el-button>
            </div>
          </el-form-item>
          <el-form-item>
            <el-table
              :data="tableData"
              border
              :resizable="false"
              style="width: 100%"
              @row-click="handleRowClick"
            >
              <el-table-column prop="name" label="名称"> </el-table-column>
              <el-table-column prop="address" label="道路"> </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <div v-else>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="选择所在地：" required>
          <el-col :span="4">
            <el-form-item prop="province">
              <el-select
                v-model="ruleForm.province"
                placeholder="请选择"
                @focus="city1Focus"
                @change="city1Change"
              >
                <el-option
                  v-for="item in provinces"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" v-if="ruleForm.province">
            <el-form-item prop="city">
              <el-select
                v-model="ruleForm.city"
                placeholder="请选择"
                @focus="city2Focus"
                @change="city2Change"
              >
                <el-option
                  v-for="item in cities"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" v-if="ruleForm.city">
            <el-form-item prop="region">
              <el-select
                v-model="ruleForm.region"
                placeholder="请选择"
                @focus="city3Focus"
                @change="city3Change"
              >
                <el-option
                  v-for="item in regiones"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="小区/大楼：" prop="building">
          <div style="display: flex">
            <el-input
              style="width: 50%; margin-right: 5px"
              v-model="ruleForm.building"
              placeholder="请输入大楼/小区名称搜索"
            ></el-input>
            <el-button type="primary" @click="getAddressList">搜索</el-button>
            <el-button type="primary">确定</el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <el-table
            :data="tableData"
            border
            :resizable="false"
            style="width: 100%"
            @row-click="handleRowClick"
          >
            <el-table-column prop="name" label="名称"> </el-table-column>
            <el-table-column prop="address" label="道路"> </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    isPop: {
      type: Boolean,
      default: true,
    },
    temporaryAddress: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      provinces: [],
      cities: [],
      regiones: [],
      ruleForm: {
        province: "",
        city: "",
        region: "",
        building: "",
      },
      rules: {
        province: [{ required: true, message: "必填字段", trigger: "change" }],
        city: [{ required: true, message: "必填字段", trigger: "change" }],
        region: [{ required: true, message: "必填字段", trigger: "change" }],
        building: [{ required: true, message: "必填字段", trigger: "blur" }],
      },

      tableData: [],
      myKey: "WGOBZ-JTR35-ACLIY-IGOU6-X2L63-XGFP3",
    };
  },
  watch: {
    temporaryAddress: {
      handler() {
        this.ruleForm.building = this.$props.temporaryAddress;
      },
    },
  },
  mounted() {},
  computed: {},
  methods: {
    handleClose() {
      this.$emit("dialogVisible", false);
    },
    getAddressList() {
      this.$jsonp("https://apis.map.qq.com/ws/place/v1/search", {
        boundary: `region(${this.ruleForm.province}, 0)`,
        keyword: `${this.ruleForm.province}${this.ruleForm.city}${this.ruleForm.region}${this.ruleForm.building}`,
        page_size: 20,
        page_index: 1,
        key: this.myKey,
        output: "jsonp",
      }).then((res) => {
        this.tableData = [];
        res.data.forEach((item) => {
          this.tableData.push({ name: item.title, address: item.address });
        });
      });
    },
    city1Focus() {
      if (this.provinces.length == 0) {
        this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
          key: this.myKey,
          callbackName: "QQmap",
          output: "jsonp",
        }).then((res) => {
          res.result[0].forEach((item) => {
            this.provinces.push({ name: item.fullname, id: item.id });
          });
        });
      }
    },
    city2Focus() {
      if (
        this.cities.length == 0 ||
        this.ruleForm.province != sessionStorage.getItem("province")
      ) {
        sessionStorage.setItem("province", this.ruleForm.province);
        this.cities = [];
        this.provinces.forEach((item) => {
          if (item.name == this.ruleForm.province) {
            this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
              key: this.myKey,
              id: item.id,
              callbackName: "QQmap",
              output: "jsonp",
            }).then((res) => {
              res.result[0].forEach((item) => {
                this.cities.push({
                  name: item.fullname,
                  id: item.id,
                });
              });
            });
          }
        });
      }
    },
    city3Focus() {
      if (
        this.regiones.length == 0 ||
        this.cities != sessionStorage.getItem("cities")
      ) {
        sessionStorage.setItem("cities", this.ruleForm.city);
        this.regiones = [];
        this.cities.forEach((item) => {
          if (item.name == this.ruleForm.city) {
            this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
              key: this.myKey,
              id: item.id,
              callbackName: "QQmap",
              output: "jsonp",
            }).then((res) => {
              res.result[0].forEach((item) => {
                this.regiones.push(item.fullname);
              });
            });
          }
        });
      }
    },
    city1Change() {
      this.ruleForm.city = "";
      this.ruleForm.region = "";
      this.getAddressList();
      this.$emit("cityChange", {
        province: this.ruleForm.province,
        city: this.ruleForm.city,
        region: this.ruleForm.region,
      });
    },
    city2Change() {
      this.ruleForm.region = "";
      this.getAddressList();
      this.$emit("cityChange", {
        province: this.ruleForm.province,
        city: this.ruleForm.city,
        region: this.ruleForm.region,
      });
    },
    city3Change() {
      this.getAddressList();
      this.$emit("cityChange", {
        province: this.ruleForm.province,
        city: this.ruleForm.city,
        region: this.ruleForm.region,
      });
    },
    getRegionalism() {},
    handleRowClick(row) {
      console.log(row);
      this.$emit(
        "rowClick",
        this.ruleForm.province + this.ruleForm.city + this.ruleForm.region,
        row.address
      );
      this.ruleForm.building = row.address;
      this.handleClose();
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .el-col {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 14px;
  margin: 0 5px;
  min-height: 32px;
}
</style>
