<template>
  <div>
    <div v-if="isShow == 0">
      <bread-crumb :crumbData="['自提站点']" :chooseCrumbData="1"></bread-crumb>
      <search-case
        :searchData="searchData"
        @inquireBtnClick="inquireBtnClick"
      ></search-case>

      <div
        style="
          padding: 10px 20px;
          border-bottom: 1px solid #eee;
          display: flex;
          align-items: center;
        "
      >
        <div style="margin-right: 5px">
          <el-button type="primary" size="mini" @click="isShow = 1">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 3px">添加站点</span>
            </div>
          </el-button>
        </div>
        <div style="margin-right: 5px">
          <el-button type="primary" size="mini" @click="isShow = 2">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 3px">统计</span>
            </div>
          </el-button>
        </div>
      </div>

      <div style="padding: 20px">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          border
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column type="selection" width="44"> </el-table-column>
          <el-table-column
            prop="id"
            sortable
            label="ID"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            sortable
            label="创建日期"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="contact_name"
            label="站长"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="site_name"
            label="名称"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="location"
            label="所在地区"
            width="140"
          ></el-table-column>
          <el-table-column
            prop="region"
            label="地址"
            width="140"
          ></el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            width="70"
          ></el-table-column>
          <el-table-column prop="setting" label="设置">
            <template slot-scope="scope">
              <button class="tab-btn" @click="handleSet(scope.row)">
                商品设置
              </button>
              <button class="tab-btn" @click="handleStatistics(scope.row)">
                统计
              </button>
              <button class="tab-btn" @click="handleCode(scope.row)">
                二维码
              </button>
              <button class="tab-btn" @click="handlePick(scope.row)">
                自提柜
              </button>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <i class="el-icon-edit poiner" @click="handleEdit(scope.row)"></i>
              <span style="margin: 0 10px"></span>
              <i
                class="el-icon-delete poiner"
                @click="handleDelete(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span style=""
            >显示第&nbsp;{{
              tableData.length == 0 ? 0 : (page - 1) * length + 1
            }}&nbsp;至&nbsp;{{
              (page - 1) * length + tableData.length
            }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
          >

          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
          >
            <el-button>首页</el-button>
            <el-button>上页</el-button>
            <el-pagination
              background
              layout=" pager,slot"
              :total="tableFinshNum"
            ></el-pagination>
            <el-button>下页</el-button>
            <el-button>末页</el-button>
          </div>
        </div>
      </div>
    </div>

    <add-group v-if="isShow == 1" @close="onClose" :data="data"></add-group>
    <statistics v-if="isShow == 2" @close="onClose"></statistics>
  </div>
</template>
<script>
import addGroup from "./childrens/station-add.vue";
import statistics from "./childrens/statistics-add.vue";
import { getTime } from "../../../../until/getTime";

export default {
  components: {
    addGroup,
    statistics,
  },
  data() {
    return {
      isShow: 0,

      searchData: {
        date: "",
        QueryContent: "",
        inquirePlaceholder: "输入要查询的内容",
        isInquireValue: true,
        isInquireBtn: true,
        isThirdSelect: true,
        thirdSelectPlaceholder: "请选择状态",
        thirdSelectOptiones: ["待审核", "已审核", "已关闭"],
      },

      tableData: [],
      multipleSelection: [],
      tableFinshNum: 0,
      page: 1,
      length: 10,
      commercial_id: "",
      data: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.getTabList();
  },
  computed: {},
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async getTabList() {
      try {
        const res = await this.$http.post("/system/pick_site/lst", {
          commercial_id: this.commercial_id,
          currentPage: this.page,
          currentLength: this.length,
        });
        console.log(res);
        this.tableData = res.data.data;
        this.tableFinshNum = res.data.count;
      } catch (error) {
        console.log(error);
      }
    },
    handleEdit(row) {
      this.data = row;
      this.isShow = 1;
    },
    handleDelete(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/system/pick_site/del", { id: row.id })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  type: "success",
                  message: res.data.msg,
                });
                this.getTabList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    inquireBtnClick(data) {
      console.log(data);
      this.$http
        .post("/system/pick_site/search", {
          firstTime: getTime(data.dateRange[0]),
          lastTime: getTime(data.dateRange[1]),
          search: data.inquireValue,
          status: data.thirdSelect,
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    onClose(data) {
      this.isShow = data;
      this.getTabList();
      this.data = "";
    },
  },
};
</script>
<style scoped lang="less">
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
.tab-btn {
  border: 1px solid #dddddd;
  padding: 3px 6px;
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
}
/deep/ .cell {
  padding: 0 !important;
}
</style>
